export const RESPONSE_CODE_ADMIN_PANEL = {
  200: 'Успешно сохранен',
  500: 'Не корректный запрос, попробуйте по другому',
};

export const RESPONSE_CODE_PRODUCTR = {
  500: 'Упс, Ваш запрос не можем обработать, попробуйте что нибудь другое',
  503: 'Упс, в данный момент сервер не работет, попробуйте позже',
};

export const TOAST_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error',
};

export const URL = 'https://api.ampercenter.ru';
