import { combineReducers } from '@reduxjs/toolkit';

import bannersSlice from './banners';
import productsSlice from './products';
import authSlice from './auth';

const rootReducer = combineReducers({
  banners: bannersSlice,
  products: productsSlice,
  auth: authSlice,
});

export default rootReducer;
