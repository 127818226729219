import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  productsData: [],
  recommedationProducts: [],
  popularProducts: [],
  isLoading: true,
  isLoadingRec: false,
  isLoadingPopular: false,
  currentProduct: {},
  isCreated: false,
  isShowMap: false,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductsData(state, action) {
      const { payload: productsData } = action;
      return { ...state, productsData };
    },
    setIsLoading(state, action) {
      const { payload: isLoading } = action;
      return { ...state, isLoading };
    },
    setIsLoadingRec(state, action) {
      const { payload: isLoadingRec } = action;
      return { ...state, isLoadingRec };
    },
    setIsLoadingPopular(state, action) {
      const { payload: isLoadingPopular } = action;
      return { ...state, isLoadingPopular };
    },
    setRecommendationsProduct(state, action) {
      const { payload: recommedationProducts } = action;
      return { ...state, recommedationProducts };
    },
    setCurrentProduct(state, action) {
      const { payload: currentProduct } = action;
      return { ...state, currentProduct };
    },
    setPopularProducts(state, action) {
      const { payload: popularProducts } = action;
      return { ...state, popularProducts };
    },
    changeStatusIsCreated(state, action) {
      const {
        payload: isCreated,
      } = action;
      return { ...state, isCreated };
    },
    changeIsShowMap(state) {
      const {
        isShowMap,
      } = state;
      return { ...state, isShowMap: !isShowMap };
    },
  },
});

export const getProductsData = createAction('products/getProductsData');
export const getPopularProduct = createAction('products/getPopularProduct');
export const createProducts = createAction('products/createProduct');
export const getRecommendedProducts = createAction('products/getRecommendedProducts');
export const getFiltredProduct = createAction('products/getFiltredProduct');
export const getCurrentProductData = createAction('products/getCurrentProductData');
export const deleteProduct = createAction('product/deleteProduct');
export const editProduct = createAction('product/editProduct');

export const {
  setProductsData,
  setIsLoading,
  setRecommendationsProduct,
  setCurrentProduct,
  setPopularProducts,
  changeStatusIsCreated,
  changeIsShowMap,
  setIsLoadingRec,
  setIsLoadingPopular,
} = productsSlice.actions;

export default productsSlice.reducer;
