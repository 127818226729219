import React, { memo } from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

import './index.scss';

const GlobalNotification = () => (
  <ToastContainer
    hideProgressBar
    pauseOnFocusLoss
    position="top-left"
    limit={3}
  />
);

export default memo(GlobalNotification);
