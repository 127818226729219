import {
  takeEvery,
  call,
  put,
  select,
} from 'redux-saga/effects';

import {
  getProductsData,
  setProductsData,
  createProducts,
  getRecommendedProducts,
  setRecommendationsProduct,
  getFiltredProduct,
  getCurrentProductData,
  setCurrentProduct,
  deleteProduct,
  editProduct,
  getPopularProduct,
  setPopularProducts,
  changeStatusIsCreated,
  setIsLoadingRec,
  setIsLoadingPopular,
  setIsLoading,
} from '../reducer/products';
import showNotify from '../../utils';
import { TOAST_TYPES } from '../../constants';
import axiosInstance from '../axios';

function* getProducts() {
  try {
    const options = {
      method: 'GET',
      url: '/products',
    };
    const { data } = yield call(axiosInstance, options);
    const transformData = data.data.map((item) => ({
      ...item,
      recommendations: item.recommendations !== 0,
      relevance: item.relevance !== 0,
    }));
    yield put(setProductsData(transformData));
  } catch (error) {
    showNotify({ message: 'Упс... Запрос не прошел, попробуйте позже', type: TOAST_TYPES.ERROR });
  }
}

function* createProduct({ payload }) {
  try {
    const options = {
      method: 'POST',
      url: '/products/create',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: payload,
    };
    const { data } = yield call(axiosInstance, options);
    const products = yield select((store) => store.products.productsData);
    const response = [
      ...products,
      data.data,
    ];
    yield put(setProductsData(response));
    yield put(changeStatusIsCreated(true));
    showNotify({ message: 'Успешно сохранено', type: TOAST_TYPES.SUCCESS });
  } catch (error) {
    showNotify({ message: 'Проверьте, загружена ли картинка, все поля заполнены', type: TOAST_TYPES.ERROR });
  }
}
function* getRecommendedProductsData() {
  try {
    const options = {
      method: 'GET',
      url: '/products?recommendations=true',
    };
    const { data } = yield call(axiosInstance, options);
    yield put(setRecommendationsProduct(data.data.filter((product) => product.relevance)));
    yield put(setIsLoadingRec(false));
  } catch (error) {
    showNotify({ message: 'Упс... Запрос не прошел, попробуйте позже', type: TOAST_TYPES.ERROR });
  }
}

function* getPopularProductData() {
  try {
    const options = {
      method: 'GET',
      url: '/products?popular=true',
    };
    const { data } = yield call(axiosInstance, options);
    yield put(setPopularProducts(data.data.filter((product) => product.relevance)));
    yield put(setIsLoadingPopular(false));
  } catch (error) {
    showNotify({ message: 'Упс... Запрос не прошел, попробуйте позже', type: TOAST_TYPES.ERROR });
  }
}

function* getFiltredProductData({ payload }) {
  const { params, type } = payload;
  try {
    const is = params.capacityRange === '90-110';
    if (is) {
      params.capacityRange = '';
    }
    const options = {
      method: 'GET',
      url: '/products',
      params,
    };

    const { data } = yield call(axiosInstance, options);
    if (type === 'productById') {
      const productData = data.data.find((product) => product.id === Number(params.id));
      yield put(setCurrentProduct(productData));
    } else {
      let arr = data.data;
      if (is) {
        arr = data.data
          .filter(
            (i) => Number(i.capacity >= 90) && Number(i.capacity) <= 110,
          );
      }
      yield put(setProductsData(arr.filter(((product) => product.relevance))));
      yield put(setIsLoading(false));
    }
  } catch (error) {
    showNotify({ message: 'Упс... Запрос не прошел, попробуйте позже', type: TOAST_TYPES.ERROR });
  }
}

function* onDeleteProduct({ payload }) {
  try {
    const { id } = payload;
    const options = {
      method: 'DELETE',
      url: `/products/${id}`,
    };
    yield call(axiosInstance, options);
    const products = yield select((store) => store.products.productsData);
    const filteredBanners = products.filter((product) => product.id !== id);
    yield put(setProductsData(filteredBanners));
    showNotify({ message: 'Успешно удалено', type: TOAST_TYPES.SUCCESS });
  } catch (error) {
    showNotify({ message: 'Упс... Запрос не прошел, попробуйте позже', type: TOAST_TYPES.ERROR });
  }
}

function* onEditProduct({ payload }) {
  try {
    const { id } = payload;
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value);
      formData.append('_method', 'patch');
    });
    const options = {
      method: 'POST',
      url: `/products/${id}`,
      data: formData,
    };
    const { data } = yield call(axiosInstance, options);
    const products = yield select((store) => store.products.productsData);
    const updatedProducts = products.map((product) => {
      if (product.id === id) return data.data;
      return product;
    });
    yield put(setProductsData(updatedProducts));
    showNotify({ message: 'Успешно изменено', type: TOAST_TYPES.SUCCESS });
  } catch (error) {
    showNotify({ message: 'Упс... Запрос не прошел, попробуйте позже', type: TOAST_TYPES.ERROR });
  }
}

export default function* watchProducts() {
  yield takeEvery(getProductsData.toString(), getProducts);
  yield takeEvery(createProducts.toString(), createProduct);
  yield takeEvery(getRecommendedProducts.toString(), getRecommendedProductsData);
  yield takeEvery(getFiltredProduct.toString(), getFiltredProductData);
  yield takeEvery(getCurrentProductData.toString(), getFiltredProductData);
  yield takeEvery(deleteProduct.toString(), onDeleteProduct);
  yield takeEvery(editProduct.toString(), onEditProduct);
  yield takeEvery(getPopularProduct.toString(), getPopularProductData);
}
