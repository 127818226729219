import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  bannersData: null,
  bannersError: null,
};

const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    setBannerData(state, action) {
      const { payload: bannersData } = action;
      return { ...state, bannersData };
    },
    setBannerError(state, action) {
      const {
        payload: bannersError,
      } = action;
      return { ...state, bannersError };
    },
  },
});

export const getBannerData = createAction('banners/getBannerData');
export const createBanner = createAction('banners/createBanner');
export const deleteBanner = createAction('banners/deleteBanner');
export const editBanner = createAction('banner/editBanner');

export const {
  setBannerData,
  setBannerError,
} = bannersSlice.actions;

export default bannersSlice.reducer;
