// eslint-disable-next-line no-unused-vars
import { fork, all } from 'redux-saga/effects';

import watchBanner from './banners';
import watchProducts from './products';

function* rootSaga() {
  yield all([
    fork(watchBanner),
    fork(watchProducts),
  ]);
}

export default rootSaga;
