import {
  takeEvery,
  call,
  put,
  select,
} from 'redux-saga/effects';

import {
  getBannerData,
  setBannerData,
  createBanner,
  deleteBanner,
  editBanner,
} from '../reducer/banners';
import showNotify from '../../utils';
import { TOAST_TYPES } from '../../constants';
import axiosInstance from '../axios';

function* getBanners() {
  try {
    const options = {
      method: 'GET',
      url: '/banners',
    };
    const { data } = yield call(axiosInstance, options);
    yield put(setBannerData(data));
  } catch (error) {
    showNotify({ message: 'Упс... Запрос не прошел, попробуйте позже', type: TOAST_TYPES.ERROR });
  }
}

function* createBanners({ payload }) {
  try {
    const options = {
      method: 'POST',
      url: '/banners/create',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: payload,
    };
    const { data } = yield call(axiosInstance, options);
    const banners = yield select((store) => store.banners.bannersData);
    const response = [
      ...banners,
      data.data,
    ];
    yield put(setBannerData(response));
    showNotify({ message: 'Успешно сохранено', type: TOAST_TYPES.SUCCESS });
  } catch (error) {
    showNotify({ message: 'Проверьте, загружена ли каартинка, указн ли заголовок', type: TOAST_TYPES.ERROR });
  }
}

function* onDeleteBanner({ payload }) {
  try {
    const { id } = payload;
    const options = {
      method: 'DELETE',
      url: `/banners/${id}`,
    };
    yield call(axiosInstance, options);
    const banners = yield select((store) => store.banners.bannersData);
    const filteredBanners = banners.filter((banner) => banner.id !== id);
    yield put(setBannerData(filteredBanners));
    showNotify({ message: 'Успешно удалено', type: TOAST_TYPES.SUCCESS });
  } catch (error) {
    showNotify({ message: 'Упс... Запрос не прошел, попробуйте позже', type: TOAST_TYPES.ERROR });
  }
}

function* onEditBanner({ payload }) {
  try {
    const {
      id,
      title,
      image,
    } = payload;
    const formData = new FormData();
    formData.append('title', title);
    formData.append('image', image);
    formData.append('_method', 'patch');
    const options = {
      method: 'POST',
      url: `/banners/${id}`,
      headers: {
        'Content-Type': 'multipart/form-formData',
      },
      data: formData,
    };
    const { data } = yield call(axiosInstance, options);
    const banners = yield select((store) => store.banners.bannersData);
    const updatedBanners = banners.map((banner) => {
      if (banner.id === id) return data.data;
      return banner;
    });
    yield put(setBannerData(updatedBanners));
    showNotify({ message: 'Успешно изменено', type: TOAST_TYPES.SUCCESS });
  } catch (error) {
    showNotify({ message: 'Упс... Запрос не прошел, попробуйте позже', type: TOAST_TYPES.ERROR });
  }
}

export default function* watchBanner() {
  yield takeEvery(getBannerData.toString(), getBanners);
  yield takeEvery(createBanner.toString(), createBanners);
  yield takeEvery(deleteBanner.toString(), onDeleteBanner);
  yield takeEvery(editBanner.toString(), onEditBanner);
}
