import React, {
  memo,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

const Notification = ({ toastProps, message }) => {
  const { type } = toastProps;

  const typeMessage = useMemo(
    () => {
      if (type === 'error') {
        return 'Error.';
      }
      if (type === 'success') {
        return 'Success!';
      }
      if (type === 'warning') {
        return 'Warning.';
      }
      return '';
    },
    [type],
  );

  return (
    <div
      className={classNames(
        'global-notification',
        `global-notification-${type}`,
      )}
    >
      <span className="global-notificationtext">
        {`${typeMessage} ${message}`}
      </span>
    </div>
  );
};

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  toastProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(Notification);
