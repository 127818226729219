import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { GridLoader } from 'react-spinners';

import GlobalNotification from './components/GlobalNotification';

import './App.css';

const Test = () => (
  <div style={{
    position: 'absolute',
    left: '45%',
    top: '45%',
  }}
  >
    <GridLoader color="red" size="45px" />
  </div>
);
const MainPage = lazy(() => import('./pages/MainPage'));
const AdminPanel = lazy(() => import('./pages/AdminPanel'));
const FiltredProducts = lazy(() => import('./pages/FiltredProducts'));
const ProductPage = lazy(() => import('./pages/ProductPage'));

const LINKS = [
  {
    path: '/',
    element: <MainPage />,
  },
  {
    path: '/admin/*',
    element: <AdminPanel />,
  },
  {
    path: '/filtred-products/:params',
    element: <FiltredProducts />,
  },
  {
    path: '/product/:id',
    element: <ProductPage />,
  },
  {
    path: '/*',
    element: <MainPage />,
  },
];

const App = () => (
  /* TODO: MAKE LOADING */
  <>
    <Suspense fallback={<Test />}>
      <Routes>
        {
        LINKS.map((link) => (
          <Route
            key={link.path}
            path={link.path}
            element={link.element}
          />
        ))
      }
      </Routes>
    </Suspense>
    <GlobalNotification />
  </>

);

export default App;
