import React from 'react';
import { toast } from 'react-toastify';

import { TOAST_TYPES } from '../constants';

import Notification from '../components/GlobalNotification/Notifications';

const DEFAULT_DURATION = 5000; // 5sec

export default ({ message, type, duration }) => {
  const options = { autoClose: duration || DEFAULT_DURATION };
  switch (type) {
    case TOAST_TYPES.SUCCESS: {
      toast.success(<Notification message={message} />, options);
      break;
    }
    case TOAST_TYPES.WARNING: {
      toast.warning(<Notification message={message} />, options);
      break;
    }
    case TOAST_TYPES.INFO: {
      toast.info(<Notification message={message} />, options);
      break;
    }
    case TOAST_TYPES.ERROR: {
      toast.error(<Notification message={message} />, options);
      break;
    }
    default: {
      toast(<Notification message={message} />, options);
    }
  }
};
